<template>
  <div class="paging">
    <a
      href="#none"
      class="first"
      :class="{ disabled: currentPage === 1 || loading }"
      @click.prevent.stop="firstPage"
      ><img src="@/assets/img/page-arrow-first.png" alt="첫 페이지로 이동"
    /></a>
    <a
      href="#none"
      class="prev"
      :class="{ disabled: currentPage === 1 || loading }"
      @click.prevent.stop="prevPage"
      ><img src="@/assets/img/page-arrow-left.png" alt="이전 페이지로 이동"
    /></a>
    <a
      href="#"
      v-for="item in range(minPage, maxPage)"
      :key="item"
      :class="[{ paging_on: currentPage === item }, { disabled: loading }]"
      @click.prevent.stop="changePage(item)"
      >{{ item }}</a
    >
    <a
      href="#none"
      class="next"
      :class="{ disabled: currentPage === totalPages || loading }"
      @click.prevent.stop="nextPage"
      ><img src="@/assets/img/page-arrow-right.png" alt="다음 페이지로 이동"
    /></a>
    <a
      href="#none"
      class="last"
      :class="{ disabled: currentPage === totalPages || loading }"
      @click.prevent.stop="lastPage"
      ><img src="@/assets/img/page-arrow-last.png" alt="마지막 페이지로 이동"
    /></a>
  </div>
</template>
<script>
export default {
  name: "base-pagination",
  props: {
    pageCount: {
      type: Number,
      default: 0,
      description: "pageCount",
    },
    perPage: {
      type: Number,
      default: 10,
      description: "perPage",
    },
    total: {
      type: [String, Number],
      default: 0,
      description: "total",
    },
    currentPage: {
      type: Number,
      default: 1,
      description: "currentPage",
    },
    loading: {
      type: Boolean,
      default: true,
      description: "Table loading",
    },
  },
  computed: {
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.currentPage >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.currentPage;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.currentPage - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      if (this.currentPage >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.currentPage;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    },
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
    };
  },
  methods: {
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage(item) {
      this.$emit("change", item);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("change", this.currentPage + 1);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit("change", this.currentPage - 1);
      }
    },
    firstPage() {
      this.$emit("change", 1);
    },
    lastPage() {
      this.$emit("change", this.totalPages);
    },
  },
  watch: {
    perPage() {
      this.$emit("change", 1);
    },
    /*
    total() {
      this.$emit("change", 1);
    },
    */
  },
};
</script>
