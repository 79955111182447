<template>
  <main class="festival_order">
    <h1>결제내역/진행현황</h1>
    <div class="table_area">
      <base-table
        :useNo="true"
        :totalItems="totalItems"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :loading="contentLoading"
        :fields="fields"
        :items="items"
        @click="onTableClick"
      >
        <template v-slot:statusText="slotProps">
          <span :class="`order_status_${slotProps.item.status}`">{{
            slotProps.item.statusText
          }}</span>
        </template>
      </base-table>
    </div>
    <base-pagination
      :total="totalItems"
      :loading="contentLoading"
      :currentPage="currentPage"
      :pageCount="totalPages"
      :perPage="pageSize"
      @change="fetch"
    />
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BasePagination from "@/components/core/BasePagination.vue";
export default {
  name: "MypageFestivalOrder",
  components: {
    BaseTable,
    BasePagination,
  },
  data() {
    return {
      fields: [
        {
          key: "registerDate",
          width: "120px",
          label: "결제일",
        },
        {
          key: "optionData.operatingDay",
          width: "100px",
          label: "운영일수",
          useClick: true,
        },
        {
          key: "optionData.operatingCount",
          width: "100px",
          label: "운영개수",
          useClick: true,
        },
        {
          key: "reference.festivalName",
          width: "350px",
          label: "축제명",
          useClick: true,
        },
        {
          key: "statusText",
          width: "140px",
          label: "진행상태",
          useSlot: true,
          useClick: true,
        },
        {
          key: "total",
          width: "140px",
          label: "금액",
          formatter: {
            name: "number",
          },
          useClick: true,
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser"]),
  },
  methods: {
    onTableClick(params) {
      this.$router.push({
        name: "MypageFestivalOrderDetail",
        params: { id: params.item.id },
      });
    },
    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };
      await client.orderUserFindAll(this.getUser.id, params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            item.product.name =
              item.product.name != null ? item.product.name : "-";
            item.registerDate = item.registerDate.split(" ")[0];
            if (item.status == 1) {
              item.statusText = "주문(결제완료)";
            } else if (item.status == 2) {
              item.statusText = "검수 중";
            } else if (item.status == 3) {
              item.statusText = "보완요청";
            } else if (item.status == 4) {
              item.statusText = "개발진행";
            } else if (item.status == 5) {
              item.statusText = "개발완료";
            } else if (item.status == 6) {
              item.statusText = "운영 중";
            } else if (item.status == 7) {
              item.statusText = "운영 종료";
            } else if (item.status == 8) {
              item.statusText = "취소(환불)";
            } else if (item.status == 9) {
              item.statusText = "환불";
            } else if (item.status == 10) {
              item.statusText = "주문(결제전)";
            }
            try {
              item.reference.festivalStartDate = `${this.$d(
                new Date(item.reference.festivalStartDate).getTime(),
                "short"
              )} ~ ${this.$d(
                new Date(item.reference.festivalEndDate).getTime(),
                "short"
              )}`;
            } catch (e) {
              console.log(e);
              item.festivalStartDate = "-";
            }
          });
          this.items = data.items;
          this.totalItems = data.totalItems;
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
